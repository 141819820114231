// $primary: #a51616;
$primary: #ba1319; /* MAIN COLOR */
$secondary: #202020; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

h1, h2, h3, nav {font-family: 'Galada', cursive;
}

p { font-family: 'Arima Madurai', cursive;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar-default {background: url('../img/header-bg.png') repeat-x;
				 border: 0px;

				@media (max-width: 767px) {
					background: url('../img/mobile-header-bg.png') repeat-x 50% 50%;
				 }
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
		    height: 45px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	color: $primary;
	    }
	}
}

.navbar-right {
	margin-top: 25px;
	font-size: 2em;


	@media (max-width: 767px) {
		margin-top: 15px;
		font-size: 1.75em;
		margin-top: 0px !important;
		border-bottom: 0px !important;
		}


	@media (max-width: 1024px) {
		margin-top: 15px;
		font-size: 1.75em;
		margin-bottom: 0px;
		}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 20px;
	}
}

.navbar {margin-bottom: 0px;

		@media (max-width: 1199px) {
			margin-bottom: 0px;
		}
			
		}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

/* ---------------- HEADER ------------------- */

.header {background: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 30%;
		 background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-webkit-background-size: cover; 

	@media (max-width: 991px) {
		background-position: 80% 0%;
	}


	@media (max-width: 768px) {
		background: url('../img/banner2.jpg');
		background-position: 0% 0%;
	}

	
}

.header-overlay {
    background-color: rgba(186, 19, 25, 0.5);
    padding: 100px 0px;

	@media (max-width: 1199px) {
		padding: 15px 0px;
	}

	@media (max-width: 1024px) {
		padding: 15px 0px;
	}

	@media (max-width: 767px) {
		padding: 30px 0px 65px;
	}


}


.header h1 {color: $wht;
			font-size: 4.25em;
			margin-top: 65px;
			text-shadow: 3px 3px #000;

		@media (max-width: 767px) {
			font-size: 2.25em !important;
			text-align: center !important;
		}	

		 @media (min-width: 768px) and (max-width: 1024px){
			font-size: 2.5em;
			text-align: center !important;
		}		

		@media (max-width: 1199px) {
			font-size: 3.5em;
			text-align: left;
		}	
	}
.header p {color: $wht;
		   font-size: 2em;
		   letter-spacing: 2px;
		   line-height: 50px;
		   list-style: none;
		   text-shadow: 2px 2px #000;
		   margin-left: 20px;

		@media (max-width: 767px) {
			font-size: 1.5em !important;
			text-align: center !important;
			line-height: 30px !important;
			margin-left: 0px;
		}
		 
		 @media (min-width: 768px) and (max-width: 1024px){
			font-size: 1.5em;
			text-align: center !important;
			margin-left: 0px !important;
			line-height: 35px;
		}	

		@media (max-width: 1199px) {
			font-size: 1.75em;
			text-align: left;
			line-height: 40px;
		}
		   
		   }



.logo {width: 100%;
	   max-width: 320px;
	
	   @media (max-width: 1024px) {
		   width: 100%;
		   max-width: 239px;
		   padding: 10px -10px 0px 0px;
	   }
	   @media (max-width: 1199px) and (orientation: landscape) {
				   margin-top: 10px;
		}
}

/* =========== Buttons ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color:$primary;
    border-color: $wht;
	   color: $wht;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $secondary;
    color: $primary;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}
/* ============== Features ============ */


.features {background: url('../img/features-bg.png');
		 background-repeat: no-repeat;
		 background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-webkit-background-size: cover;
		border-top: 15px solid $secondary;
		border-bottom: 15px solid $secondary;

	@media (max-width: 767px) {
		background-position: 10% 100%;
	}

	@media (max-width: 1199px) {
		background-position: 25% 100%;
	}

}

.features-overlay {
    background-color: rgba(186, 19, 25, 0.5);
    padding: 0px;

	@media (max-width: 767px) {
		padding: 25px;
		background-color: rgba(186, 19, 25, 0.75);
	}

	@media (max-width: 1024px) {
		padding: 15px;
	}
	@media (max-width: 1199px) {
		padding: 0px;
	}

}

.features h1 {color: $wht;
			font-size: 4.5em;
			margin-top: 10px;
			text-shadow: 3px 3px #000;
			text-align: center;
			padding: 25px;

		@media (max-width: 1024px){
			font-size: 3.5em;
			text-align: center;
			padding-top: 15px;
			padding-bottom: 0px;
			margin-left: -20px;
		}	

		@media (max-width: 1199px){
			padding-bottom: 25px;
		}	

	}


.features h2 {color: $wht;
			font-size: 3em;
			margin-top: 10px;
			text-shadow: 3px 3px #000;
			margin-left: 15px;
			
		    @media (max-width: 767px){
				font-size: 1.75em !important;
				text-shadow: none;
				margin: 0px;
				text-align: center !important;
				padding-top: 50px;
			}

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
			padding-top: 50px;
		}	
		@media (max-width: 1199px) {
			text-align: left;
			font-size: 2.5em;
			margin: 0px;
		}	
}


.features p {color: $wht;
		   font-size: 1.5em;
		   letter-spacing: 2px;
		   line-height: 40px;
		   margin-left: 15px;
		   
		 
		 @media (max-width: 767px){
			font-size: 1.5em;
			text-align: center !important;
			line-height: 30px;
			margin-top: 25px;
			margin-left: 0px !important;
		}	

		@media (min-width: 768px) and (max-width: 1023px) {
			text-align: center !important;
		}
		   
		@media (max-width: 1199px) {
			text-align: left;
			line-height: 35px;
			margin-left: 0px;
		}

		   }

.features div i {

	text-shadow: none;
}

.features img {width: 100%;
			   display: block;
			   margin: 0 auto;
			   max-width: 585px;

 @media (max-width: 768px) {
	 margin-top: 0px !important;
 }


 @media (max-width: 1024px){ 

	 		margin-top: 65px !important;
 }

}



/* ============ Benefits ========== */

.benefits {text-align: center;
		   border-bottom: 15px solid #000;


}

.benefits h1 {color: $primary;
			font-size: 4.25em;
			margin-top: 10px;
			text-shadow: 2px 2px #c3c3c3;
			text-align: center;
			padding-top: 50px;
			padding-bottom: 50px;

		@media (max-width: 1024px){
			font-size: 2.5em;
			text-align: center;
			padding-top: 15px;
			padding-bottom: 20px;
			margin-left: 0px;
			text-shadow: none;
		}
		@media (max-width: 1199px){
			font-size: 3em;
			text-shadow: 2px 2px #c3c3c3;
		}			
	}


.benefits h2 {color: $primary;
			font-size: 3em;
			margin-top: 10px;
			text-shadow: 2px 2px #c3c3c3;
			
			@media (max-width: 767px) {
				margin-top: 0px;
				font-size: 2em;
				text-shadow: none;
			}
			
			
			}


.benefits p {color: $secondary;
		   font-size: 1.5em;
		   letter-spacing: 1px;
		   line-height: 35px;
		   padding-bottom: 50px;
		   
		 
		 @media (max-width: 767px){
			font-size: 1.5em;
			text-align: center;
		}	
		   
		   }


.benefits div i {color: $primary;
				 font-size: 200px;
    			 height: 200px;
				 display:inline-block;
				 line-height: 25px;
			     padding-top: 100px;
				 margin-bottom: 50px;

}

/* ============ Why Us ========== */

.why-us {
		background-image: url('../img/why-us-background.png');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		-webkit-background-size: cover;
		text-align: center;


}

.why-us-overlay {
    background-color: rgba(31, 31, 31, 0.65);
    padding: 0px;

	@media (max-width: 767px) {
		padding: 25px;
	}

	@media (max-width: 1024px) {
		padding: 15px;
	}
	@media (max-width: 1199px) {
		padding: 0px;
	}

}

.why-us h1 {color: $wht;
			font-size: 4.25em;
			margin-top: 10px;
			text-shadow: 2px 2px #BA1319;
			text-align: center;
			padding-top: 50px;
			padding-bottom: 50px;

		@media (max-width: 1024px){
			font-size: 3.5em;
			text-align: center;
			margin-left: -150px;
			padding-bottom: 0px;
		}
		
		@media (max-width: 1024px){
			font-size: 3.5em;
			margin: 0px;
			padding-bottom: 50px;
		}			
	}



.why-us p {color: $wht;
		   font-size: 1.5em;
		   letter-spacing: 1px;
		   line-height: 35px;
		   padding-bottom: 50px;
		   
		 
		 @media (max-width: 767px){
			font-size: 1.25em;
			text-align: center;
			line-height: 25px;
		 }	
		   
		   }


.why-us div i {color: $primary;
				 font-size: 200px;
    			 height: 200px;
				 display:inline-block;
				 line-height: 25px;
			     padding-top: 100px;
				 margin-bottom: 50px;

}
